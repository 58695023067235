import * as React from "react";
import { graphql } from "gatsby";
import Styled from "styled-components/macro";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import HomeConversion from "../../components/pages/home/HomeConversion";
import HomeTemplates from "../../components/pages/home/HomeTemplates";
import HomePrototyping from "../../components/pages/home/HomePrototyping";
import HomeFeedback from "../../components/pages/home/HomeFeedback";
import HomeStyling from "../../components/pages/home/HomeStyling";
import HomeIdeation from "../../components/pages/home/HomeIdeation";
import HomeWireframe from "../../components/pages/home/HomeWireframe";
import HomeBrainstorming from "../../components/pages/home/HomeBrainstorming";
import HeaderBanner from "../../components/pages/product/HeaderBanner";
import FooterBanner from "../../components/pages/product/FooterBanner";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import BlogHighlight from "../../components/pages/blog/BlogHighlight";
import Shoutout from "../../components/pages/signup/Shoutout";
import Content from "../../components/layout/Content/Content";

import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/Design-101-Design-basics-for-non-designers.png";
import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/What-s-the-difference-between-UI-and-UX.png";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/Five Considerations When-Designing-an-MVP-for-Your-Startup.png";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Home = ({ data }) => (
  <Layout>
    <Meta
      title="Digital Product Design Made Easy, Powered By AI | Uizard"
      description="Digital product design for apps, websites, and desktop software in minutes. If you can use Google Slides or PowerPoint, you can use Uizard!"
      url="/product/"
    />

    <HeaderBanner imageA={data.bannerImageA.childImageSharp} />

    <HomeWireframe
      tagline="Artificial Intelligence"
      headline="Design with AI"
      description="Gone are the days of tedious, time-consuming design work.
      Bring your creative vision to life with the help of a cutting-edge AI assistant.
      Whether you're a seasoned designer or a beginner, Uizard makes it easy to create stunning designs that look like they were crafted by a pro."
      imageA={data.themeGenerationImageA.childImageSharp}
      imageB={data.themeGenerationImageB.childImageSharp}
      videoName="uizard-theme-generation"
      imageAlt="Video showing Uizard AI-powered smart theme feature."
      link="/design-assistant/"
    />

    <HomePrototyping
      tagline="UX Design Tool"
      headline="Rapid prototyping"
      description="It should only take seconds to test an idea, not days! Drag-and-drop
      components and templates for quick and easy <a:https://uizard.io/prototyping/>UI prototyping</a> of mobile apps, web apps, and websites. Design digital product UIs in minutes."
      imageA={data.prototypingImageA.childImageSharp}
      imageB={data.prototypingImageA.childImageSharp}
      imageAlt="drag and drop pre-made ui templates and components in Uizard."
    />

    <HomeWireframe
      tagline="UI Wireframing"
      headline="Wireframe in seconds"
      description="Turn wireframe mode on at any time to render your project in low-fidelity.
      Use wireframe mode to take a perspective that enables you to create great user flows and rewarding user experiences."
      imageA={data.wireframesToMockupsBig.childImageSharp}
      imageB={data.wireframesToMockupsSmall.childImageSharp}
      videoName="uizard-wireframe-high-fi-mode"
      imageAlt="using uizard wireframe mode to render designs in low-fidelity."
      link="/wireframing/"
      videoW={580}
    />

    <HomeIdeation
      tagline="Product Design Made Easy"
      headline="Ideate & iterate like never before"
      description="Test different styles instantly with the power of Uizard themes.
      Use themes to experiment with your team in a UX design sprint, to adapt the style of your design for
      different audiences, or simply to explore ideas!"
      imageAlt="using uizard theming to render designs in high-fidelity."
      imageA={data.themeGenerationImageA.childImageSharp}
      imageB={data.themeGenerationImageB.childImageSharp}
      videoName="uizard-theme-generation"
    />

    <HomeTemplates
      tagline="UI Templates"
      headline="Fast track your web or app UI design"
      description="Everyone can create professional designs with Uizard. Use our pre-made
      <a:https://uizard.io/templates/>UI templates</a> and Uizard's drag-and-drop features to bring your ideas to
      life in minutes. Never fear the blank screen again!"
      imageA={data.templatesImageA.childImageSharp}
      imageB={data.templatesImageB.childImageSharp}
      imageAlt="a team using pre-made templates in uizard."
      link="/templates/"
    />

    <HomeFeedback
      tagline="Collaborative Design"
      headline="Collaborate with anyone, anywhere, anytime"
      description="Collaborating with your team on UX design projects
        in Uizard is fast and easy. Just share a link to your project and start collaborating
        immediately with your colleagues from your web browser.
        No software install required, just pure productivity."
      imageA={data.feedBackImageA.childImageSharp}
      imageB={data.feedBackImageB.childImageSharp}
      imageAlt="collaboration in real-time in Uizard."
    />

    <HomeFeatured />

    <BlogHighlight
      title="Learn digital product design"
      blogs={[
        {
          image: blogThumbnail1,
          link: "https://uizard.io/blog/design-101-design-basics-for-non-designers/",
          alt: "Thumbnail for blog titled Design 101: Design basics for non-designers",
        },
        {
          image: blogThumbnail2,
          link: "https://uizard.io/blog/whats-the-difference-between-ui-and-ux/",
          alt: "Thumbnail for blog titled What's the difference between UI and UX?",
        },
        {
          image: blogThumbnail3,
          link: "https://uizard.io/blog/five-considerations-when-designing-an-mvp/",
          alt: "Thumbnail for blog titled Five Considerations When Designing an MVP for Your Startup",
        },
      ]}
    />

    <ContentExtended>
      <Shoutout
        headline="Design. Simplified"
        description="Bring your product design to life in minutes"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(
      relativePath: { eq: "product/uizard-product-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerImageB: file(
      relativePath: { eq: "product/uizard-drag-and-drop-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageA: file(
      relativePath: { eq: "home/prototyping-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 722, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    prototypingImageB: file(
      relativePath: { eq: "home/prototyping-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageA: file(
      relativePath: { eq: "home/conversion-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 938, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    conversionImageB: file(
      relativePath: { eq: "home/conversion-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageA: file(relativePath: { eq: "home/templates-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2260, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    templatesImageB: file(relativePath: { eq: "home/templates-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    stylingImageA: file(relativePath: { eq: "home/styling-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2120, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    stylingImageB: file(relativePath: { eq: "home/styling-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1290, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageA: file(
      relativePath: { eq: "home/uizard-theme-generation-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageB: file(
      relativePath: { eq: "home/uizard-theme-generation-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsBig: file(
      relativePath: { eq: "wireframing/WireframesToMockups_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframesToMockupsSmall: file(
      relativePath: { eq: "wireframing/WireframesToMockups_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageA: file(
      relativePath: { eq: "home/brainstorming-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    brainstormingImageB: file(
      relativePath: { eq: "home/brainstorming-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1326, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    feedBackImageA: file(relativePath: { eq: "home/feedback-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1462, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    feedBackImageB: file(relativePath: { eq: "home/feedback-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 774, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageA: file(
      relativePath: { eq: "home/uizard-theme-generation-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeGenerationImageB: file(
      relativePath: { eq: "home/uizard-theme-generation-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
